<template>
  <div>
    <load-profile></load-profile>
    <b-card v-if="data.id">
      <b-row class="mb-3">
        <b-col class="text-center">
          <b-avatar
            size="60"
            class="avatar-border mb-1"
            variant="light-primary"
            :text="avatarText(data.ownerName)"
            :src="data.image"
          >
            <feather-icon v-if="!data.ownerName" icon="UserIcon" size="32" />
          </b-avatar>
          <h3 class="mb-0">
            {{ data.ownerName }}
          </h3>
        </b-col>
      </b-row>

      <h5>{{ $t("field.generalInformation") }}</h5>
      <b-table-simple
        responsive
        borderless
        striped
        class="table-information mb-0"
      >
        <b-tbody>
          <b-tr>
            <b-td>
              {{ $t("field.region") }}
            </b-td>
            <b-td>
              {{ data.regionCode }}
            </b-td>
            <b-td>
              {{ $t("field.agent") }}
            </b-td>
            <b-td>
              {{ data.agentCode }}
            </b-td>
            <b-td>
              {{ $t("field.salesperson") }}
            </b-td>
            <b-td>
              {{ data.salespersonName }}
              <span class="text-primary d-block">
                {{ data.salespersonIdCard }}
              </span>
            </b-td>
            <b-td>
              {{ $t("field.name") }}
            </b-td>
            <b-td>
              {{ trans(data, "name", $i18n.locale) }}
            </b-td>
            <b-td>
              {{ $t("field.outletSegment") }}
            </b-td>
            <b-td>
              {{ trans(data, "outletSegmentName", $i18n.locale) }}
            </b-td>
            <b-td>
              {{ $t("field.contactNumber") }}
            </b-td>
            <b-td>
              {{ data.contactNumber }}
            </b-td>
            <b-td>
              {{ $t("field.secondaryNumber") }}
            </b-td>
            <b-td>
              {{ data.secondaryNumber }}
            </b-td>
            <b-td>
              {{ $t("field.address") }}
            </b-td>
            <b-td colspan="5">
              {{ trans(data, "address", $i18n.locale) }}
            </b-td>
            <b-td>
              {{ $t("field.latitude") }}
            </b-td>
            <b-td>
              {{ data.latitude }}
            </b-td>
            <b-td>
              {{ $t("field.longitude") }}
            </b-td>
            <b-td>
              {{ data.longitude }}
            </b-td>
            <b-td>
              {{ $t("field.createdDate") }}
            </b-td>
            <b-td>
              {{ data.createdAt | formatDate }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="mt-3" v-if="data.ams.length">
        <h5>{{ $t("field.ams") }}</h5>
        <b-table-simple
          responsive
          borderless
          striped
          class="table-information mb-0"
        >
          <b-tbody>
            <b-tr>
              <template v-for="(item, index) in data.ams">
                <b-td :key="`ams-label-${index}`">{{ item.name }}</b-td>
                <b-td :key="`ams-value-${index}`">{{ item.quantity }}</b-td>
              </template>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BAvatar,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import { avatarText } from "@core/utils/filter";

const OutletDraftRepository = Repository.get("outletDraft");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BAvatar,

    NButtonLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      data: {},

      avatarText,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      OutletDraftRepository.detail(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = {
            ...data,
          };
        }
      });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "outlet-draft";
    const route = "outlet-draft";

    return { resource, route };
  },
};
</script>